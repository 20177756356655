(function ($) {
	var socialContent = {
		options: {selector: '#social-content', tabSelector: '.social-content-link-tab', tabActiveClass: 'social-content-link-active', contentSelector: '.social-content-content'}, clickTab: function (e) {
			var $this = $(this), $socialContent = $(socialContent.options.selector), linkParts = $this.attr('href').split('#');
			if (linkParts.length < 2) {
				return;
			}
			e.preventDefault();
			$socialContent.find(socialContent.options.contentSelector).hide();
			$socialContent.find(socialContent.options.tabSelector).removeClass(socialContent.options.tabActiveClass);
			$('#' + linkParts[1]).show();
			$this.addClass(socialContent.options.tabActiveClass);
		}, init: function () {
			$(socialContent.options.selector).on('click', socialContent.options.tabSelector, socialContent.clickTab);
		}
	};
	$(document).ready(socialContent.init);
}(jQuery));